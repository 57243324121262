<template>
    <div id="contact">
	<p>Mukhin Aleksey, email: mukhin[dog]bionet[dot]nsc[dot]ru, web-service developer </p>
	<p>Levitsky Victor, email: levitsky[dog]bionet[dot]nsc[dot]ru, method developer</p>
    </div>
</template>

<script>
    export default {
        name: "Contact"
    }
</script>

<style scoped>

</style>
